.page-produkt {
	.accordion * {
		color: black;
	}

	section.top {
		@include margin-top(100px);
		/* GSAP Flicker fix */
		visibility: hidden;
		opacity: 0;

		small.cat {
			@include font-size(16px);
			text-transform: uppercase;
			color: rgba(white, 0.4);
			@include antialiased;
			display: inline-block;
			@include media-breakpoint-up(md) {
				@include font-size(20px);
			}
			a {
				color: rgba(white, 0.4);
				display: inline-block;
			}
			.line {
				width: 25px;
				height: 1px;
				background: rgba(#6d6d6d, 0.7);
				display: inline-block;
				vertical-align: middle;
				position: relative;
				top: -2px;
				margin-left: 15px;
				margin-right: 10px;
				clip-path: inset(0 100% 0 0);
			}
		}
		h1 {
			font-size: flexible-font(50, 220);
			text-transform: uppercase;
			@include antialiased;
			margin-bottom: 0;
			margin-top: 0.4em;

			hyphens: auto;
			-webkit-hyphens: auto;
			padding-top: 0.2em;
			&.smaller {
				font-size: flexible-font(50, 190);
				@include media-breakpoint-down(sm) {
					font-size: 43px;
				}
			}
			&.bigger {
				font-size: flexible-font(70, 310);
				margin-top: 0.2em;
				@include media-breakpoint-down(sm) {
					font-size: 50px;
				}
			}

			@include media-breakpoint-down(sm) {
				font-size: 45px;
			}
			.line .lineInner {
				height: 1em;
				padding-top: 0.2em;
			}
		}
		&.tepelne-pojistky {
			.img {
				@include media-breakpoint-up(md) {
					top: -4.5em;
					left: 3em;
				}
			}
		}
		&.svorkovnice-a-konektory,
		&.civky-a-zalevaci-pouzdra,
		&.transformatorove-plechy {
			.img {
				width: 100%;
				@include media-breakpoint-up(md) {
					width: 90%;
				}
			}
		}
		&.vysokofrekvencni-lanka,
		&.lepici-a-ochranne-pasky,
		&.svorkovnice-a-konektory {
			.img {
				@include media-breakpoint-up(md) {
					width: 70%;
				}
			}
		}
		.img {
			width: 100%;
			@include ratio(67%);
			background: transparent;
			margin: 0 auto;

			top: -1em;
			@include media-breakpoint-up(md) {
				top: -4em;
				width: 80%;
			}

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		hr {
			color: white;
			opacity: 0.32;
			position: relative;
			top: -5em;
			z-index: -1;
			margin: 0;
			@include media-breakpoint-up(md) {
				top: -9em;
			}
			@include media-breakpoint-up(lg) {
				top: -17em;
			}
		}

		&.navijeci-stroje,
		&.vicevretenove-navijeci-stroje {
			hr {
				top: 0;
				margin: 3em 0;
			}
		}

		&.elektroizolacni-laky {
			.img {
				top: -1em;
				@include media-breakpoint-up(md) {
					top: -5em;
				}
			}

			hr {
				top: -2em;
				@include media-breakpoint-up(md) {
					top: -10em;
				}
			}
		}
	}

	section.body {
		&.ferity {
			.info .brands .img {
				@include media-breakpoint-up(md) {
					height: 100px;
				}
			}
		}

		.info {
			small {
				text-transform: uppercase;
				letter-spacing: 0.01em;
				@include antialiased;
				@include font-size(20px);
				border: 1px solid white;
				border-radius: 30px;
				padding: 1px 22px;
			}
			p {
				display: block;
				@include margin-top(35px);
				text-transform: uppercase;
				@include font-size(42px);
				line-height: 119%;
				letter-spacing: -0.01em;
				@include antialiased;
			}
			.brands {
				.img {
					width: 100%;
					height: 40px;
					background: transparent;
					@include media-breakpoint-up(md) {
						margin-left: auto;
						height: 80px;
						width: 280px;
						margin-bottom: 1em;
					}
					img {
						object-fit: contain;
						object-position: right center;
					}
				}
			}
		}
		.galerie {
			@include margin-top(160px);
			.img {
				width: 100%;
				@include ratio(73%);
				border-radius: 10px;
			}
			.tns-controls {
				button {
					position: relative;
					top: 10px;
				}
			}
		}
		.podnadpis {
			margin-top: 4em;

			p {
				@include font-size(21px);
				text-transform: uppercase;
				letter-spacing: -0.01em;
				text-indent: 30px;
				line-height: 167%;
				@include antialiased;
				@include media-breakpoint-up(md) {
					text-indent: 70px;
				}
			}
		}
	}

	section.katalogy {
		margin-top: 8em;
		padding-bottom: 8em;
		border-bottom: 1px solid rgba(white, 0.32);
		.heading {
			h2 {
				@include font-size(95px);
				@include antialiased;
				text-transform: uppercase;
				display: inline-block;
				position: relative;
				&:after {
					content: '';
					display: inline-block;
					width: 139px;
					height: 139px;
					background: url(/img/badge-cr-sk.svg) center no-repeat;
					background-size: contain;
					position: absolute;
					right: -24%;
					top: 0;
					transform: translateY(-56%);
					@include media-breakpoint-down(md) {
						transform: scale(0.8) translateY(-80%);
						right: -28%;
					}
				}
			}
		}
		



    #katalogy_accordion{
      input {
        position: absolute;
        opacity: 0;
        z-index: -1;
      }
      
      .collapsible {
        &-accordion {
          overflow: hidden;
          margin-bottom: 7px;
        }
        &-item {
          &-label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            
          }
          &-content {
            max-height: 0;
            transition: all 0.5s;
          }
        }
      }
      
      input:checked {
        ~ .collapsible-item-content {
          max-height: 200vh;
          background: #2e3030;
        }
        ~ label{
          background: #2e3030;
        }
        ~ label button {
          background: rgba(255, 255, 255, 0.18);
          color: white;
          &:after{
            transform: rotate(-135deg);
            border-color: white;
            top: 8px;
          }
        }
      }
    }
    .katalog_new{
      background: #212323;
      border-radius: 5px;
      transition: background 0.2s;
      &:hover{
        background: #2e3030;
      }
      .katalog_title{
        padding: 26px 0px 26px 0px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include media-breakpoint-up(md) {
          padding: 26px 36px 26px 22px;
          flex-direction: row;
        }
        h3{
          margin: 0;
          display: flex;
          align-items: center;
          span.num{
            color: white;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            border: 1px solid white;
            @include font-size(17px);
            letter-spacing: 0.01em;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            @include antialiased;
            @include media-breakpoint-up(md) {
              width: 60px;
              height: 60px;
              @include font-size(20px);
              margin-right: 17px;
            }
          }
          span.title{
            margin: 0;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: white;
            @include font-size(26px);
            @include antialiased;
          }
          @include media-breakpoint-down(md) {
            align-self: flex-start;
          }
        }
        button{
          border: none;
					background: transparent;
					box-shadow: none;
					outline: none;
					background: white;
					color: black;
					letter-spacing: 0.01em;
					text-transform: uppercase;
					@include font-size(15px);
					padding: 17px 52px;
          height: min-content;
					border-radius: 40px;
					transition: all 0.2s;
					white-space: nowrap;
          pointer-events: none;
					@include media-breakpoint-up(md) {
						@include font-size(18px);
					}
					&:after {
						content: '';
						display: inline-block;
						border: solid;
						border-color: black;
						border-width: 0 2px 2px 0;
						display: inline-block;
						padding: 4px;
						transform: rotate(45deg);
						vertical-align: super;
						position: relative;
						top: 3px;
						margin-left: 20px;
						transition: all 0.2s;
					}
          @include media-breakpoint-down(md) {
            align-self: flex-end;
            margin-top: 1em;
          }
        }
      }
      .soubory{
        
        .soubory_wrap{
          @include padding-top(20px);
				  @include padding-bottom(60px);
          padding-inline: 12px;
          @include media-breakpoint-up(md) {
            padding-inline: 34px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }
        }
        
        .soubor{
          background-color: white;
          border-radius: 8px;
          padding: 20px 22px 24px 28px;
          margin-bottom: 15px;
          display: flex;
          justify-content: space-between;
          @include media-breakpoint-up(md) {
            width: calc(50% - 7px);
          }
          .soubor_left{
            .title{
              @include font-size(19px);
              letter-spacing: 0.01em;
              color: #2c2c2c;
              text-decoration: underline;
              text-decoration-color: rgba(#1e1e1e, 0.2);
              text-underline-offset: 8px;
            }
            .size{
              display: block;
              @include font-size(15px);
              letter-spacing: 0.03em;
              text-transform: uppercase;
              color: rgba(black, 0.4);
              margin-top: 10px;
            }
          }
          .links{
            display: flex;
            align-items: center;
            button.popupToggle,a{
              border: none;
              background: transparent;
              box-shadow: none;
              outline: none;
              @include ir;
              min-width: 50px;
              width: 50px;
              aspect-ratio: 1/1;
            }
            a{
              background: url(/img/btn-download.svg) center no-repeat;
              background-size: contain;
              
            }
            button.popupToggle{
              background: url(/img/btn-view.svg) center no-repeat;
              background-size: contain;
              margin-right: 5px;
              @include media-breakpoint-down(md) {
                display: none!important;
              }
            }
          }
        }
      }
      .soubor_popup{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(black, 0.9);
        display: none;
        justify-content: center;
        align-items: center;
        
        &.open{
          display: flex;
        }
        .soubor_popup_content{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .popup_close{
            position: absolute;
            top: 2%;
            right: 2%;
            border: none;
            box-shadow: none;
            outline: none;
            background-color: transparent;
            svg{
              pointer-events: none;
            }
          }
          embed{
            width: 90%;
            height: 90%;
          
          }
        }
      }
    }
	}

	section.lide {
		margin: 0;
		border-bottom: 1px solid rgba(white, 0.32);
		@include padding-top(90px);
		@include padding-bottom(100px);
		.leftPart {
			@include media-breakpoint-down(md) {
				margin-bottom: 2.5em;
			}
			.img {
				width: 97%;
				margin-left: auto;
				margin-right: 0;
				@include ratio(100%);
				border-radius: 5px;
				display: block;
				@include media-breakpoint-up(md) {
					left: 15px;
				}

				img {
					border-radius: 5px;
				}
			}
		}
		.rightPart {
			@include media-breakpoint-up(md) {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				@include padding-left(60px);
			}

			h2.tinyHeading {
				text-transform: uppercase;

				color: rgba(white, 0.5);
				@include font-size(21px);
				@include antialiased;
				margin-bottom: 2em;
				@include media-breakpoint-up(md) {
					margin: 0;
				}
			}
			.info {
				@include media-breakpoint-up(md) {
					position: relative;
					top: -20px;
				}
			}
			h3 {
				@include font-size(54px);
				font-family: $fb, sans-serif;
				@include antialiased;
				margin: 0;
				small {
					@include font-size(19px);
					letter-spacing: 0.01em;
					color: rgba(white, 0.4);
					display: block;
					font-family: $ff, sans-serif;
					line-height: 1;
          span{
            @include font-size(19px);
            letter-spacing: 0.01em;
            color: rgba(white, 0.4);
          }
				}
			}
			.contact {
				margin: 3.5em 0 5em 0;
				a {
					@include font-size(25px);
					letter-spacing: 0.01em;
					text-transform: uppercase;
					@include antialiased;
					display: block;
					&.email {
						margin-top: 0.6em;
						text-decoration: underline;
						text-underline-offset: 8px;
					}
				}
			}
			a.btn-primary {
				@include media-breakpoint-up(md) {
					padding-left: 50px;
					padding-right: 50px;
					position: relative;
					left: -15px;
				}

				&:after {
					width: 32px;
				}
			}
		}
	}

	section.dalsiProdukty {
		.heading {
			@include padding-top(160px);
			@include padding-bottom(70px);
		}
		h2 {
			font-size: flexible-font(40, 150);
			text-transform: uppercase;
			@include antialiased;
			line-height: 109%;
			margin: 0;
		}
		.custom-controls {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;

			button {
				transition: opacity 0.2s;
				transform: translateY(70%);
				&[data-controls='prev'] {
					transform: rotate(180deg) translateY(-70%);
				}
				&[disabled] {
					opacity: 0.2;
				}
				@include media-breakpoint-up(md) {
					transform: translateY(-40%);
					&[data-controls='prev'] {
						transform: rotate(180deg) translateY(40%);
					}
				}
			}
		}
		article {
			h3 {
				@include multilineUnder(white, 0%, 2px);
				transition: background-size 0.2s;
			}
			&:hover {
				h3 {
					background-size: 100% 2px;
				}
			}
		}
	}

	.tns-controls,
	.custom-controls {
		text-align: right;
		button {
			@include ir;
			display: inline-block;
			width: 78px;
			height: 78px;
			background: url(/img/arr-slider.svg) center no-repeat;
			margin: 0 5px;
			z-index: 1;
			background-size: contain;

			&[data-controls='prev'] {
				transform: rotate(180deg);
			}
		}
	}
}


/* for katalog popup */
body.popup_open{
  overflow: hidden;
}