header {
	margin-top: 20px;
	margin-bottom: 20px;
	@include media-breakpoint-up(md) {
		margin: 20px 20px 20px 0px;
	}

	.logoPart {
		h1 {
			margin: 0;
			font-size: 0;
		}
		.logo {
			display: inline-block;

			svg {
				width: 122px;
				height: 25px;

				@media only screen and (min-width: 1500px) {
					transform: scale(1.2);
				}
			}

			@include media-breakpoint-up(md) {
				margin-top: 12px;
			}
			@include media-breakpoint-down(md) {
				width: 112px;
			}

			#m,
			#e,
			#g {
				transform: translate(0);
				transition: transform 0.4s cubic-bezier(0.92, 0.012, 0.108, 1);
			}
			&:hover {
				#m {
					transform: translateY(10px);
				}
				#e {
					transform: translateX(10px);
				}
				#g {
					transform: translateX(-10px);
				}
			}
		}
	}

	.menuPart {
		text-align: right;

		#menuBtn {
			width: 35px;
			height: 25px;
			display: inline-block;
			margin: 0;
			position: relative;

			.bar {
				$barHeight: 2px;
				width: 100%;
				height: $barHeight;
				background-color: white;
				display: block;
				position: absolute;

				&:first-child {
					top: 0;
				}

				&:last-child {
					bottom: 0;
				}

				&:nth-child(2) {
					top: calc(50% - #{$barHeight/2});
				}
			}
		}
	}

	.leftPart,
	.rightPart {
		& > ul {
			display: flex;
			align-items: center;
			li {
				padding: 18px 0 23px 0;
				a {
					letter-spacing: 0.05em;
					text-transform: uppercase;
					font-size: flexible-font(17, 23, 1440, 2280);
					@include antialiased;
				}
			}
		}
	}

	.leftPart {
		& > ul > li {
			white-space: nowrap;
			@include margin-right(36px);
		}
		li#submenu {
			padding-left: 14px;
			padding-right: 14px;
			border-radius: 10px 10px 0px 0px;
			background-color: $primary;

			&:after {
				content: '';
				display: inline-block;
				border: solid white;
				border-width: 0 1px 1px 0;
				display: inline-block;
				padding: 4px;
				transform: rotate(45deg);
				vertical-align: middle;
				position: relative;
				top: -8px;
				margin-left: 5px;
			}
		}
		li#submenu > .submenuWrap {
			position: absolute;
			top: 80px;
			left: 12px;
			right: 12px;
			background-color: white;
			z-index: 999;
			display: flex;
			border-radius: 0px 30px 30px 30px;
			box-shadow: 0 46px 46px 0 rgba(0, 0, 0, 0.2);
			overflow: hidden;

			opacity: 0;
			visibility: hidden;
			@media only screen and (min-width: 2000px) {
				top: 90px;
			}
			.submenuContent {
				width: 33.33%;
				padding: 30px 20px 15px 30px;

				&:hover {
					a.img img {
						transform: scale(1.05);
					}
					h3 a span {
						&:before {
							width: 0%;
						}
					}
				}

				&:last-of-type {
					border-right: none;
				}
				h3 {
					@include margin-top(22px);
					@media only screen and (min-width: 1500px) {
						margin-top: 1em;
						margin-bottom: 1em;
					}
					a {
						font-size: flexible-font(20, 38);
						font-family: $fb, sans-serif;
						letter-spacing: -0.01em;
						text-transform: uppercase;
						color: rgba(black, 0.8);
						@include antialiased;
						small {
							font-family: 'TT Commons', sans-serif;

							font-size: flexible-font(14, 22, 1440, 2280);
							position: relative;
							vertical-align: super;
							display: inline-block;
							margin-left: 10px;
							letter-spacing: 0.07em;
							top: -2px;
						}
						span {
							/* border-bottom: 2px solid #f0f0f0; */
							@include underline(2px, 0, #f0f0f0);
							&:before {
								width: 100%;
								transition: width 0.2s;
							}
						}
					}
				}
				a.img {
					display: block;
					text-decoration: none;
					@include ratio(83%);
					border-radius: 10px;
					background-color: white;
					max-height: 500px;
					img {
						transform: scale(1);
						transition: all 0.2s;
					}
				}
			}
		}
		/* na hover */
		li#submenu:hover {
			background-color: white;

			&:after {
				border: solid black;
				border-width: 0 1px 1px 0;
			}
			a {
				color: black;
			}
			& > a {
				position: relative;
				&:before {
					content: '';
					display: inline-block;
					position: absolute;
					width: 40%;
					height: 280%;
					background-color: transparent;
					bottom: -90%;
					right: -63%;
				}
			}
			.submenuWrap {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	.rightPart {
		li.zajem {
			border-radius: 30px;
			border: 1px solid white;
			padding: 3px 15px;
			white-space: nowrap;
			position: relative;
			top: -2px;

			/* background-image: linear-gradient(white, white 100%);
			background-position: left bottom;
			background-size: 0% 100%;
			background-repeat: no-repeat;
			transition: all 0.2s; */
			/* &:hover {
				background-size: 100% 100%;
				a {
					color: black;
				}
			}
			a {
				color: white;
				transition: color 0.2s;
			} */
			&:hover {
				a {
					&:after {
						transform: translateY(4px);
					}
					&:before {
						transform: translateY(-100%);
					}
				}
			}
			a {
				position: relative;
				color: transparent;
				overflow: hidden;
				display: inline-block;
				&:after {
					color: white;
					display: inline-block;
					content: attr(data-word);
					position: absolute;
					top: -2px;
					left: -1px;

					transform: translateY(100%);
					transition: transform 0.3s;
					@include slideEase;
				}
				&:before {
					color: white;
					display: inline-block;
					content: attr(data-word);
					position: absolute;
					top: -2px;
					left: -1px;

					transform: translateY(4px);
					transition: transform 0.3s;
					@include slideEase;
				}
			}
		}
		ul > li {
			@include margin-left(36px);
		}
		ul {
			justify-content: flex-end;
		}
		li.zajemMobile {
			padding: 0;
			svg {
				width: 35px;
				position: relative;
				top: -6px;
			}
		}
	}

	/* animace na hover */
	#kontakty,
	#onas {
		a {
			/* border: 1px solid blue; */
			position: relative;
			overflow: hidden;
			display: inline-block;
			color: transparent;
			&:hover {
				&:after {
					transform: translateY(4px);
				}
				&:before {
					transform: translateY(-100%);
				}
			}
			&:after {
				color: white;
				display: inline-block;
				content: attr(data-word);
				position: absolute;
				top: -1px;
				left: -1px;
				/* border: 1px solid red; */
				transform: translateY(100%);
				transition: transform 0.3s;
				@include slideEase;
			}
			&:before {
				color: white;
				display: inline-block;
				content: attr(data-word);
				position: absolute;
				top: -1px;
				left: -1px;
				/* border: 1px solid green; */
				transform: translateY(4px);
				transition: transform 0.3s;
				@include slideEase;
			}
		}
	}
}
