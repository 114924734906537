.page-about {
	section.top {
		/* GSAP Flicker fix */
		visibility: hidden;
		opacity: 0;
		h1 {
			font-size: flexible-font(60, 295);
			text-transform: uppercase;
			@include margin-top(100px);
			margin-bottom: 0;
			@include media-breakpoint-up(xl) {
				white-space: nowrap;
			}
			.line { 
				height: 1em;
			}
			margin-bottom: 0.1em;  
      
		}
		.img {
			border-radius: 20px;
			width: 100%;
			@include ratio(59%);
			img {
				border-radius: 20px;
			}
		}
	}

	section.usp {
		margin: 4em 12px 6em 12px;
		border-top: 1px solid rgba(white, 0.32);
		border-bottom: 1px solid rgba(white, 0.32);

		padding: 0;
		@include media-breakpoint-up(md) {
			margin: 4em 30px 11em 30px;
			padding: 4em 0;
			display: flex;
			justify-content: space-between;

			.wrap:first-of-type {
				padding-left: 40px;
			}
			.wrap:last-of-type {
				padding-right: 110px;
			}
		}

		@include media-breakpoint-down(md) {
			.wrap {
				text-align: center;
				@include margin-bottom(60px);
				@include margin-top(60px);
			}
		}
		strong,
		span {
			display: block;
		}
		strong {
			font-size: flexible-font(50, 179);
			font-family: $fb, sans-serif;
			margin-bottom: 0;
			line-height: 1;
		}
	}

	section.certificate {
		h2.secondHeading {
			line-height: 116%;
			@include margin-bottom(50px);
		}
		.img {
			border-radius: 5px;
			width: 100%;
			@include ratio(135%);
			img {
				border-radius: 5px;
			}
		}
		.leftPart {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			p {
				margin-bottom: 0;
				width: 80%;
				margin-left: auto;
				margin-right: 0;
			}
		}
		.rightPart {
			position: relative;
			@include media-breakpoint-down(md) {
				@include margin-bottom(80px);
			}
			svg {
				position: absolute;
				left: -30px;
				top: 110px;
				@include media-breakpoint-down(md) {
					transform: scale(0.8);
					left: 0;
					top: 20px;
				}
			}
		}
	}

	section.marqueeBrands {
		@include media-breakpoint-up(md) {
			margin-top: 20em;
		}
	}

	section.bannerContact {
		padding-top: 4em;
		padding-bottom: 10em;
		background: #212323;
		margin-bottom: 0;
		@include media-breakpoint-up(md) {
			margin-top: 16em;
			padding-bottom: 16em;
		}
		@media only screen and (min-width: 1500px) {
			max-width: none;
			padding-left: 8px;
			padding-right: 8px;
		}
		h2 {
			margin-bottom: 0.1em;
			.lineInner {
				padding-top: 0.3em;
				height: 1em;
			}
		}
		p {
			@include font-size(16px);
			line-height: 167%;
			color: rgba(white, 0.6);
			text-transform: uppercase;
			letter-spacing: -0.01em;
			text-align: center;
			display: block;
			margin: 0.5em auto 3.5em auto;
			@include antialiased;

			@include media-breakpoint-up(md) {
				width: 40%;
				@include font-size(21px);
				margin: 2em auto 5.5em auto;
			}
		}
		a {
			margin-top: 2em;
		}
	}

	footer {
		margin-top: 0;
	}
}
