.page-certifikaty {
	section.top {
		margin-bottom: 4em;
		h1 {
			font-size: flexible-font(50, 120);
			text-transform: uppercase;
			@include antialiased;
			margin-bottom: 0;
			margin-top: 0.4em;

			hyphens: auto;
		}
	}
	section.certifikaty {
		h3 small {
			display: block;
			@include font-size(15px);
			letter-spacing: 0.03em;
			text-transform: uppercase;
			color: rgba(rgb(255, 255, 255), 0.4);
			margin-top: 5px;
		}
		.buttonAcc {
			display: inline-block;
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
		.accordion-item {
			&:hover {
				&:hover {
					background: #2e3030;
				}
			}
		}
	}
}
