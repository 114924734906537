.page-contact {
	address {
		margin: 0;
	}
	.top {
		/* GSAP Flicker fix */
		visibility: hidden;
		opacity: 0;
		border-bottom: 1px solid rgba(white, 0.32);
		margin: 0;
		@include media-breakpoint-up(md) {
			margin: 0 30px;
		}

		.middle {
			display: flex;
			flex-direction: column;
			align-items: center;
			@include margin-top(120px);
			@include margin-bottom(180px);

			.wrap {
				display: inline-flex;
				flex-direction: column;
				align-items: center;
				@include margin-bottom(80px);
			}
			.label {
				text-transform: uppercase;
				letter-spacing: 0.01em;
				@include font-size(20px);
				line-height: 1;
				border: 1px solid white;
				text-align: center;
				width: min-content;
				white-space: nowrap;
				border-radius: 30px;
				padding: 4px 14px;
				@include antialiased;
				@include margin-bottom(15px);
			}
			a.bigLink {
				font-size: flexible-font(30, 119);
				font-weight: 500;
				line-height: 1;
				font-family: $fb, sans-serif;
				text-transform: uppercase;
				@include antialiased;
				white-space: nowrap;
				.lineInner {
					height: 0.8em;
				}
				&:last-of-type {
					.lineInner {
						height: 1em;
					}
				}
			}
		}
		.bottom {
			opacity: 0.5;
			@include font-size(15px);
			letter-spacing: 0.01em;
			text-transform: uppercase;
			line-height: 126%;
			@include antialiased;
			@include margin-bottom(50px);
			@include media-breakpoint-up(md) {
				display: flex;
				justify-content: space-between;
				@include font-size(19px);
			}
			.leftPart {
				display: flex;

				@include media-breakpoint-down(md) {
					justify-content: center;
					margin-bottom: 1em;
					margin-left: -45px;
				}
				svg {
					margin-right: 15px;
					@include media-breakpoint-down(md) {
						transform: scale(0.8);
						margin-right: 5px;
					}
				}
			}
			.rightPart {
				text-align: center;
				@include media-breakpoint-up(md) {
					text-align: right;
				}
			}
		}
	}

	.kontaktniOsoby {
		@include margin-top(100px);
		.heading {
			@include margin-bottom(60px);
			h2 {
				text-transform: uppercase;
				@include font-size(75px);
				font-weight: 500;
				margin: 0;
				text-align: center;
				@include media-breakpoint-up(md) {
					text-align: left;
				}
			}
		}

		.osobaRow {
			background: #212323;
			border-radius: 5px;
			margin-bottom: 6px;
			padding: 20px;
			@include media-breakpoint-up(md) {
				padding: 30px 30px 30px 40px;
			}
			transition: all 0.2s;
			&:hover {
				background: #2e3030;
			}
			h3 {
				@include font-size(27px);
				font-family: $fb;
				font-weight: 500;
				@include antialiased;
				margin: 0;
				@include media-breakpoint-down(md) {
					margin-bottom: 20px;
				}
				small {
					display: block;
					@include font-size(15px);
					letter-spacing: 0.01em;
					opacity: 0.5;
					font-weight: 300;
          a{
            text-transform: unset;
            @include font-size(15px);
            letter-spacing: 0.01em;
            font-weight: 300;
            text-decoration: underline;
            text-underline-offset: 4px;
            text-decoration-color: rgba(white, 0.5);
          }
				}
			}
			a {
				letter-spacing: 0.01em;
				@include font-size(16px);
				line-height: 142%;
				text-transform: uppercase;
				white-space: nowrap;
				@include antialiased;
				@include media-breakpoint-up(md) {
					@include font-size(19px);
				}
			}
			.phones {
				@include media-breakpoint-down(md) {
					margin-bottom: 20px;
				}
			}
			.phones > a {
				display: block;
				margin-bottom: 0.2em;
			}

			.email > a {
				@include underline(1px, 2px, rgba(white, 0.3));
			}
		}
	}
}
