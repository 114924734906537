.page-mam-zajem {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #222224;
	footer {
		display: none;
	}
	section.top {
		position: relative;

		.flexWrap {
			min-height: 100vh;
			z-index: 0;
			display: flex;
			& > a {
				position: absolute;
				text-transform: lowercase;
				letter-spacing: 0.05em;
				font-family: $fb, sans-serif;
				font-size: flexible-font(17, 23, 1440, 2280);
				@include antialiased;
				/* font-weight: 500; */
				top: 28px;
				left: 24px;

				&:before {
					content: '';
					display: inline-block;
					padding: 4px;
					border: solid #fff;
					border-width: 0 1px 1px 0;
					transform: rotate(135deg);
					margin-right: 10px;
				}
			}

			.leftPart,
			.rightPart {
				flex: 1;
				&.leftPart {
					padding: 0em 1em 1em 1em;
					@include padding-top(70px);

					display: flex;
					flex-direction: column;
					justify-content: center;
					@include media-breakpoint-up(md) {
						padding: 0em 2em 2em 2em;
					}
					@media only screen and (min-width: 1500px) {
						padding-left: 5vw;
						padding-right: 5vw;
					}

					@include media-breakpoint-down(md) {
						justify-content: center;
					}
					h2 {
						text-transform: uppercase;
						margin-bottom: 0.5em;
						font-size: flexible-font(46, 135);
						text-align: center;
						@include media-breakpoint-up(md) {
							text-align: left;
						}
					}

					/* dropdown bootstrap */
					#myDropdown {
						margin-bottom: 20px;
						small {
							display: inline-block;
							margin-bottom: 14px;
							color: white;
							font-size: 14px;
							letter-spacing: 0.01em;
							text-transform: uppercase;
							@include antialiased;
							padding-left: 30px;
							color: rgba(#fff, 0.5);

							@include media-breakpoint-up(md) {
								@include margin-bottom(10px);
								font-size: 16px;
							}
						}
						button {
							box-shadow: none;
							outline: none;
							border: none;
							color: white;
							background: transparent;
							display: block;
							width: 100%;
							font-size: 15px;
							text-align: left;

							letter-spacing: 0.01em;
							@include antialiased;
							position: relative;
							border: 1px solid #ffffff;
							border-radius: 40px;
							padding: 22px 26px;
							display: flex;
							justify-content: space-between;
							align-items: center;
							@include media-breakpoint-up(md) {
								font-size: 17px;
							}
							&:after {
								border-top: none;
								border-left: none;
								border-right: 1px solid white;
								border-bottom: 1px solid white;
								width: 10px;
								height: 10px;
								transform: rotate(45deg);
								padding: 4px;
							}
						}

						ul {
							width: 100%;
							border-radius: 20px;
							max-height: 200px;
							@include media-breakpoint-up(md) {
								max-height: 400px;
							}
							li.dropdown-item {
								padding: 10px 22px;
								cursor: pointer;
								border-bottom: 1px solid rgba(0, 0, 0, 0.1);

								&:active,
								&:hover {
									background-color: transparent;
									color: black;
								}
							}
						}
					}

					/**
                    * ninja forms
                    */
					.nf-response-msg {
						p {
							text-transform: uppercase;
							@include font-size(19px);
							letter-spacing: 0.01em;
							opacity: 0.8;
						}
					}
					.nf-form-cont {
						.nf-form-content {
							padding: 0;
							max-width: unset !important;
						}
						//upozorneni
						.nf-form-fields-required {
							display: none;
						}

						input,
						textarea {
							background: transparent;
							border: none;
							border-bottom: 1px solid #fff;
							color: white;
							font-size: 14px;
							letter-spacing: 0.01em;
							/* text-transform: uppercase; */
							@include antialiased;
							font-family: $ff, sans-serif;
							&::placeholder {
								color: rgba(#fff, 0.5);
								text-transform: uppercase;
							}
							@include media-breakpoint-up(md) {
								font-size: 16px;
							}
						}
						textarea.ninja-forms-field {
							height: 100px;
						}
						.textarea-container {
							@include margin-bottom(50px);
						}
						//select

						#nf-field-5-container {
							display: none;
						}
						select {
							height: 70px;
							padding-left: 30px;
							padding-right: 40px;
							color: #fff;
							font-size: 15px;
							letter-spacing: 0.01em;
							@include antialiased;
							text-transform: uppercase;
							@include media-breakpoint-up(md) {
								font-size: 17px;
								padding-right: 30px;
							}
						}
						div[for='nf-field-5'] {
							background: transparent !important;
							border: none;
							border-radius: 50px;
							opacity: 1 !important;
							height: 70px;
							border: 1px solid #fff;
							border-color: #fff !important;

							//sipka
							&:after {
								content: '';
								display: inline-block;
								padding: 4px;
								border: solid #fff;
								border-width: 0 1px 1px 0;
								width: 10px;
								height: 10px;
								position: absolute;
								right: 20px;
								@include media-breakpoint-up(md) {
									right: 40px;
								}
							}

							&:after {
								bottom: 30px;
								transform: rotate(45deg);
							}
						}

						nf-field {
							&:nth-child(2),
							&:nth-child(3) {
								float: left;
								width: 47.5%;
							}
							&:nth-child(2) {
								margin-right: 5%;
							}
							&:not(:first-child) {
								.nf-field-label {
									display: none;
								}
							}
							label {
								color: white;
								font-size: 14px;
								letter-spacing: 0.01em;
								text-transform: uppercase;
								@include antialiased;
								padding-left: 30px;
								color: rgba(#fff, 0.5);

								@include media-breakpoint-up(md) {
									@include margin-bottom(10px);
									font-size: 16px;
								}
							}
						}

						//button
						.submit-wrap {
							background-color: white;
							border-radius: 36px;
						}
						.submit-container input[type='button'],.submit-container input[type='submit'] {
							width: 100%;
							background-color: #fff;
							letter-spacing: 0.01em;
							font-size: flexible-font(14, 27);
							padding-top: 20px;
							padding-bottom: 20px;
							height: unset;
							color: black;
							border: none;
							background: url(../img/btn-arrow.svg) 50% no-repeat;
							background-position: 92% 52%;
							background-size: 23px;
							text-transform: uppercase;
							@include media-breakpoint-up(md) {
								background-size: 33px;
								background-position: 92% 53%;
							}
							&:hover {
								color: black;
							}
						}
					}
				}
				&.rightPart {
					position: relative;
					.img {
						width: 100%;
						height: 100%;
						position: relative;
						img {
							@include cover;
						}
					}
					a {
						@include ir;
						position: absolute;
						top: 50px;
						right: 50px;
						background: url(../img/logozajem.svg) center no-repeat;
						background-size: contain;
						width: 208px;
						height: 40px;
					}
				}
			}
		}
	}
}
.simplebar-scrollbar::before {
	background: $primary;
	opacity: 1;
}
.simplebar-vertical {
	top: 10px !important;
	width: 12px !important;
	bottom: 10px;
	right: 9px;
}
