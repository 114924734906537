// GLOBAL STYLES
// essentially anything
// ( html, body, h1 - h6, strong, em )
// -----------------------------------

// Test if gulp autoprefix works
#autoprefixer-test {
	display: grid;
	transition: all 0.5s;
	user-select: none;
	background: linear-gradient(to bottom, white, black);
}

body {
	font-family: $ff, sans-serif;
	background-color: $primary;
	color: $secondary;
	font-weight: 300;
}

h1,
h2,
h3,
h4 {
	margin-bottom: 0.6em;
	margin-top: 0.9em;
	line-height: 1.1em;
	font-family: $fb, sans-serif;
	font-weight: 500;
}

h1 {
	font-size: 4em;
	@include antialiased;
}
h3 {
	font-family: $ff, sans-serif;
}

h2 {
	font-size: 2.5em;
}

h4 {
	font-size: 1.5em;
}

a {
	color: white;
	text-decoration: none;

	&:hover,
	&:active,
	&:focus {
		color: white;
	}
}

p {
	margin-bottom: 2rem;
	letter-spacing: 0.01em;
}

::selection {
	background-color: $secondary;
	color: $primary;
}

.img {
	position: relative;
	background-color: $placeholder;
	overflow: hidden;
	img {
		@include cover;
	}
}
ul {
	@include listDefault;
}
svg {
	margin: 0;
}

.offset-sides {
	@include media-breakpoint-up(md) {
		margin-left: 18px;
		margin-right: 18px;
	}
}

/* text styles */
.tax-title {
	letter-spacing: -0.01em;

	font-size: flexible-font(22, 42);

	small {
		font-size: flexible-font(15, 25);
		letter-spacing: 0.02em;
		color: rgba(black, 0.5);
		text-transform: lowercase;
		display: block;
		font-weight: 300;
	}
}

.product-title {
	font-weight: 500;
	@include antialiased;
	font-size: 26px;
	&.bigger {
		font-size: 36px;
		@include media-breakpoint-up(sm) {
			font-size: flexible-font(20, 55, 1200, 2000);
		}
	}
	&.smaller {
		@include media-breakpoint-up(sm) {
			font-size: flexible-font(20, 35, 1200, 2000);
		}
	}
	@include media-breakpoint-up(sm) {
		font-size: flexible-font(20, 45, 1200, 2000);
	}
}

.side-text {
	@include margin-top(85px);
	@include margin-bottom(85px);
}
p.sideText {
	@include font-size(16px);
	line-height: 167%;
	text-indent: 70px;
	letter-spacing: -0.01em;
	text-transform: uppercase;
	@include antialiased;
	@include media-breakpoint-up(md) {
		@include font-size(21px);
	}
}

span.usp {
	font-weight: 300;
	text-transform: uppercase;
	letter-spacing: 0.01em;
	line-height: 120%;
	hyphens: auto;
	font-size: flexible-font(15, 28, 768, 2280);
	@include antialiased;
}

h2.secondHeading {
	font-weight: 500;
	@include font-size(85px);
	line-height: 187%;
	text-transform: uppercase;
	margin: 0;
}

.page-htmlTpl {
	h1 {
		font-size: flexible-font(50, 120);
		text-transform: uppercase;
		@include antialiased;
		margin-bottom: 0;
		margin-top: 0.4em;

		hyphens: auto;
	}
	h2 {
		text-transform: uppercase;
	}
	p {
		font-family: $ff, sans-serif;
	}
}
