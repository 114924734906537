footer {
	margin-top: 14em;
	border-top: 1px solid rgba(white, 0.2);
	.topRow {
		@include media-breakpoint-up(md) {
			padding: 0em 3em 0em 3.5em;
		}
	}

	.bottomRow {
		border-top: 1px solid rgba(white, 0.2);
		padding: 30px 0;
		@include media-breakpoint-up(md) {
			padding: 30px;
		}
		@include media-breakpoint-down(md) {
			text-align: center;
		}
		a,
		small {
			@include font-size(13px);
			letter-spacing: 0.03em;
			text-transform: uppercase;
			@include antialiased;
		}
		small {
			opacity: 0.4;
		}
		.leftPart {
			@include media-breakpoint-down(md) {
				margin-bottom: 1em;
			}
			a {
				display: block;
				@include media-breakpoint-up(md) {
					display: inline-block;
					margin-right: 60px;
				}
			}
		}
	}

	.logoPart {
		position: relative;
		.h-line {
			display: block;
			position: absolute;
			width: 1px;
			height: 100%;
			background-color: rgba(white, 0.2);
			top: 0;
			right: 80px;
			@include media-breakpoint-down(lg) {
				display: none;
			}
		}
		svg {
			@include media-breakpoint-down(lg) {
				display: block;
				margin: 0 auto;
				transform: scale(0.8);
			}
		}
	}

	.logoPart,
	.sitemapPart {
		@include media-breakpoint-up(lg) {
			padding: 7em 0;
		}
	}

	.sitemapPart {
		text-align: center;
		margin-top: 2em;
		@include media-breakpoint-up(md) {
			margin-top: 0;
			text-align: left;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		.main {
			@include media-breakpoint-up(lg) {
				padding-left: 2.1em;
			}

			& > small {
				text-transform: uppercase;
				@include font-size(16px);
				opacity: 0.3;
				margin-bottom: 1em;
				display: block;
			}
			ul {
				li {
					padding: 5px 0;
					a {
						@include font-size(30px);
						letter-spacing: 0.03em;
						text-transform: uppercase;
						font-family: $fb, sans-serif;
						font-weight: 500;
						@include antialiased;
					}
					small {
						@include antialiased;
						@include font-size(18px);
						letter-spacing: 0;
						opacity: 1;
						vertical-align: top;
						margin-left: 5px;
						margin-bottom: 1em;
						font-family: 'TT Commons', sans-serif;
						@include antialiased;
					}
				}
			}
		}
		.sub {
			@include media-breakpoint-down(md) {
				@include margin-top(40px);
			}
			ul.bold {
				@include media-breakpoint-up(md) {
					margin-bottom: 2em;
				}

				li {
					padding-bottom: 5px;
					a {
						@include font-size(19px);
						letter-spacing: 0.03em;
						text-transform: uppercase;
						font-family: $fb, sans-serif;
						font-weight: 500;
					}
				}
			}
			ul {
				@include media-breakpoint-up(md) {
					margin-bottom: 8em;
				}

				li {
					padding-bottom: 5px;
					a {
						letter-spacing: 0.05em;
						text-transform: uppercase;
						@include font-size(13px);
						font-family: $ff, sans-serif;
						@include antialiased;
					}
				}
			}
			svg {
				display: block;
				margin: 2em auto;
				@include media-breakpoint-up(md) {
					margin: 0 0 0 auto;
				}
			}
		}
	}
}
