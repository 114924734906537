/* open-sans-300 - latin_latin-ext */
@font-face {
	font-family: 'PP Neue';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src:/* IE6-IE8 */ url('/src/assets/fonts/PPNeueMachina-InktrapSemibold.woff2') format('woff2'),
		/* Super Modern Browsers */ url('/src/assets/fonts/PPNeueMachina-InktrapSemibold.woff') format('woff'),
		/* Modern Browsers */ url('/src/assets/fonts/PPNeueMachina-InktrapSemibold.ttf') format('truetype'); /* Safari, Android, iOS */
}
@font-face {
	font-family: 'PP Neue Regular';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src:/* IE6-IE8 */ url('/src/assets/fonts/ppneuemachina-plainregular-webfont.woff2') format('woff2'),
		/* Super Modern Browsers */ url('/src/assets/fonts/ppneuemachina-plainregular-webfont.woff') format('woff'),
		/* Modern Browsers */ url('/src/assets/fonts/ppneuemachina-plainregular-webfont.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
	font-family: 'PP Neue';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src:/* IE6-IE8 */ url('/src/assets/fonts/ppneuemachina-inktrapregular-webfont.woff2') format('woff2'),
		/* Super Modern Browsers */ url('/src/assets/fonts/ppneuemachina-inktrapregular-webfont.woff') format('woff'),
		/* Modern Browsers */ url('/src/assets/fonts/ppneuemachina-inktrapregular-webfont.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
	font-family: 'TT Commons';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src:/* IE6-IE8 */ url('/src/assets/fonts/ttcommons-medium-webfont.woff2') format('woff2'), /* Super Modern Browsers */ url('/src/assets/fonts/ttcommons-medium-webfont.woff') format('woff'),
		/* Modern Browsers */ url('/src/assets/fonts/ttcommons-medium-webfont.ttf') format('truetype'); /* Safari, Android, iOS */
}
