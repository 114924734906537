.archive {
	&.page-komponenty,
	&.page-technologie {
		section.top h1 .badge {
			top: 23%;
			transform: translate(-65%, -100%);
			@media only screen and (min-width: 1500px) {
				top: 32%;
			}
		}
		section.top h1 span:not(.badge) {
			height: unset;
			-webkit-hyphens: auto;
		}
	}

	section.top {
		margin-bottom: 4em;
		/* GSAP Flicker fix */
		visibility: hidden;
		opacity: 0;

		@include media-breakpoint-up(md) {
			margin-bottom: 11em;
		}

		.top-row {
			@include margin-top(74px);
			@include margin-bottom(70px);
		}
		h1 {
			text-transform: uppercase;
			font-size: #{'max(11.5vw, 50px)'};
			font-size: flexible-font(50, 250);
			line-height: 1;
			position: relative;
			margin: 0;
			display: inline-block;
			hyphens: auto;
			@include media-breakpoint-down(sm) {
				position: static;
				display: block;
			}
			span:not(.badge) {
				display: inline-block;
				height: 1em;
				padding-top: 0.2em;

				@include media-breakpoint-down(sm) {
					display: block;
				}
			}

			small {
				display: block;
				@include font-size(20px);
				font-size: flexible-font(16, 26);
				opacity: 0.6;
				font-family: 'PP Neue Regular', sans-serif;
				margin-top: 1.4em;
			}
			.badge {
				display: inline-block;
				position: absolute;
				overflow: hidden;
				background: url(../img/badge-dodavame.svg) center no-repeat;
				background-size: contain;
				top: 28%;
				left: 100%;
				transform: translate(-25%, -100%);
				width: 25vw;
				height: 25vw;
				pointer-events: none;

				@include media-breakpoint-up(md) {
					width: 10vw;
					height: 10vw;
					max-width: 170px;
				}
				@include media-breakpoint-down(sm) {
					display: none;
					width: 0;
				}
			}
		}
		.imgWrap {
			overflow: hidden;
			border-radius: 20px;
		}
		.img {
			width: 100%;
			@include ratio(75%);
			border-radius: 20px;
			display: block;
			@include media-breakpoint-up(md) {
				@include ratio(55%);
			}
			img {
				border-radius: 20px;
			}
		}
		.side-text {
			p {
				@include font-size(16px);
				line-height: 167%;
				text-indent: 70px;
				letter-spacing: -0.01em;
				text-transform: uppercase;
				@include antialiased;
				@include media-breakpoint-up(md) {
					@include font-size(21px);
				}
			}
		}
	}
	section.list {
		article {
			margin-bottom: 30px;
			&:hover {
				h3 {
					background-size: 100% 2px;
				}
			}
			h3 {
				@include multilineUnder(white, 0%, 2px);
				transition: background-size 0.2s;
			}
		}
	}
}
