/*! purgecss start ignore */
#fullscreenMenu {
	position: fixed;
	z-index: 100;
	left: 0;
	top: 0;
	overflow-x: hidden;
	width: 100%;
	min-height: 100%;
	background: black;
	color: white;

	a {
		color: white;
	}

	visibility: hidden;
	opacity: 0;

	/* transition: opacity 0.5s; */

	.closeBtn {
		top: 4vw;
		right: 4vw;
		@include closeIcon(30px, 2px, white);
	}

	ul.menuItems {
		position: absolute;
		top: 25%;
		left: 5%;
		right: 5%;
		bottom: 25%;

		margin: 0;
		padding: 0;

		li {
			justify-content: space-around;
			list-style: none;
			margin: 0;
			padding: 0;
			text-align: center;
			overflow: hidden;
			margin: 5px 0;

			span {
				text-transform: uppercase;
				font-size: 18px;
				color: rgba(white, 0.5);
			}
		}
		.main {
			@include margin-bottom(80px);
			li {
				a {
					color: white;
					font-size: flexible-font(36, 56);
					font-family: $fb, sans-serif;
					@include antialiased;
					text-align: center;
					padding: 0;
					text-transform: uppercase;
					font-weight: 500;
					display: inline-block;
					height: 1em;
				}
			}
		}

		/* sub */
		li {
			overflow: hidden;
			margin: 5px 0;
			a {
				color: white;
				font-size: flexible-font(26, 46);
				font-family: $fb, sans-serif;
				@include antialiased;
				text-align: center;
				padding: 0;
				display: inline-block;
				text-transform: uppercase;
				font-weight: 500;
				height: 1em;
			}
		}
	}
}

/* .openedMenu {
	#fullscreenMenu {
		visibility: visible;
		opacity: 1;
	}
} */
/*! purgecss end ignore */
