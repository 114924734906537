.popup {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 999;
	&.closed {
		display: none;
	}
	.popup__overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.4);
	}

	.popup__content {
		position: absolute;
		background-color: white;
		top: 15px;
		left: 15px;
		bottom: 15px;
		right: 15px;
		@include media-breakpoint-up(md) {
			top: 50%;
			left: 50%;
			right: unset;
			bottom: unset;
			transform: translate(-50%, -50%);
			width: 720px;
		}

		.popup__close {
			top: 4vw;
			right: 4vw;
			@include ir;
			@include closeIcon(30px, 2px, white);
			overflow: visible;
			z-index: 1;
			@include media-breakpoint-up(md) {
				top: 1vw;
				right: 1vw;
				@include closeIcon(20px, 2px, black);
			}
		}

		.popup__body {
			@include media-breakpoint-up(md) {
				display: flex;
			}
			.body__left {
				.img {
					width: 100%;
					@include ratio(80%);

					@include media-breakpoint-up(md) {
						height: 100%;
					}

					img {
						display: none;
						&[data-id='1'] {
							display: block;
						}
						@include media-breakpoint-up(md) {
							&[data-id='0'] {
								display: block;
							}
							&[data-id='1'] {
								display: none;
							}
						}
					}
				}
				@include media-breakpoint-up(md) {
					width: 50%;
				}
			}
		}

		.body__right {
			padding: 1.8em 1em;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			@include media-breakpoint-up(md) {
				width: 50%;
				padding: 2.5em 1.5em 2em 1.5em;
			}

			h2,
			p {
				color: $primary;
			}
			h2 {
				margin-top: 0;
				text-transform: uppercase;
				@include media-breakpoint-down(sm) {
					font-size: 2em;
				}
			}
			a.btn-primary {
				color: white;
				background-color: $primary;
				transform: scale(0.9);
				@include media-breakpoint-up(md) {
					transform: scale(0.8);
				}
			}
		}
	}
}
