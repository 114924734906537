section.bannerContact {
	margin: 8em 0;
	@media only screen and (min-width: 1500px) {
		max-width: 1500px;
		margin: 8em auto;
	}

	h2 {
		text-transform: uppercase;
		font-size: flexible-font(40, 200);
		line-height: 91%;
		letter-spacing: 0.01em;
		margin-bottom: 0.8em;
		@include antialiased;
		@include media-breakpoint-down(md) {
			margin-bottom: 1em;
			line-height: 118%;
		}
		.line .lineInner {
			@include media-breakpoint-up(md) {
				margin-bottom: 0;
				height: 1.1em;
				padding-top: 0.3em;
			}
		}
	}
}

section.marqueeBrands {
	overflow: hidden;
	margin: 4em 0;
	@include media-breakpoint-up(md) {
		margin: 8em 0;
	}

	.top {
		border-top: 1px solid rgba(white, 0.32);
		margin: 0 30px;
	}
	&.margin {
		@include media-breakpoint-up(md) {
			margin-left: 18px;
			margin-right: 18px;
		}
	}
	svg {
		width: auto;
	}
}

.subtextPolygon {
	display: flex;
	align-items: flex-start;
	@include margin-top(30px);
	@include margin-bottom(70px);
	@include media-breakpoint-up(md) {
		@include margin-bottom(150px);
	}

	small {
		text-transform: uppercase;
		@include font-size(20px);
		line-height: 160%;
		opacity: 0.6;
		@include antialiased;
		display: block;
		font-size: flexible-font(16, 26);
	}
}

/* product cards */
section.list {
	margin: 5em 0 6em 0;
	border-top: 1px solid rgba(white, 0.32);
	@include media-breakpoint-up(md) {
		margin: 11em 20px 13em 20px;
	}

	.heading {
		margin: 1em 0 2em 0;
		& > div {
			@include media-breakpoint-up(md) {
				padding: 0;
			}
		}
	}
}

.productCol {
	@include media-breakpoint-up(md) {
		padding-left: 10px;
		padding-right: 10px;
	}
}

article.product {
	@include media-breakpoint-down(xl) {
		margin-bottom: 30px;
	}

	&:hover {
		@include media-breakpoint-up(md) {
			.znacka {
				visibility: visible;
				opacity: 1;
			}
		}

		.img > img {
			transform: scale(1.05);
		}
	}

	.znacka {
		position: absolute;
		top: 30px;
		right: 30px;
		width: 50%;

		z-index: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		@include media-breakpoint-up(md) {
			/* hover */
			visibility: hidden;
			opacity: 0;
			transition: 0.2s all;
		}

		.imgZnacka {
			width: 100%;
			@include ratio(35%);
			position: relative;
			margin-bottom: -20px;
			img {
				@include cover;
				object-fit: contain;
				object-position: right top;
			}
		}
	}
	a {
		display: block;
		position: relative;
		width: 100%;
		@include ratio(130%);
		background-color: #d9d9d9;
		border-radius: 10px;
		.img {
			background-color: transparent;
			@include cover;
			border-radius: 10px;
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: auto;
				object-fit: contain;
				border-radius: 10px;
				transform: scale(1);
				transition: transform 0.4s;
			}
		}

		.text {
			position: absolute;
			bottom: 7%;
			left: 6%;
			right: 6%;
			text-align: center;
			@include media-breakpoint-down(md) {
				bottom: 6%;
			}
			@media only screen and (min-width: 1500px) {
				left: 15%;
				right: 15%;
			}
			small {
				display: block;
				text-transform: uppercase;
				color: rgba(black, 0.2);
				letter-spacing: 0.02em;
				font-size: flexible-font(14, 22, 1440, 2280);
				@include margin-bottom(7px);
			}
			h3 {
				margin: 0;
				letter-spacing: -0.01em;
				text-transform: uppercase;
				color: rgba(black, 0.8);
				letter-spacing: -0.03em;
				font-family: $fb, sans-serif;
				display: inline-block;
				line-height: 156%;
			}
		}
	}
}

#badgeGarance {
	min-width: 130px;

	width: 11vw;
	@include media-breakpoint-up(md) {
		min-width: 160px;
	}

	@media only screen and (min-width: 1500px) {
		width: 10vw;
	}
}

.accordion-item {
	background: #212323;
	border: none;
	border-radius: 5px;
	padding: 26px 0px 26px 0px;
	margin-bottom: 7px;
	transition: all 0.2s;

	@include media-breakpoint-up(md) {
		padding: 26px 36px 26px 22px;
	}
	&.hasBtn {
		cursor: pointer;
		&:hover {
			background: #2e3030;
		}
	}

	&.opened {
		background: #2e3030;

		.buttonAcc {
			button {
				background: rgba(white, 0.18);
				color: white;

				&:after {
					transform: rotate(-135deg);
					border-color: white;
					top: 8px;
				}
			}
		}
	}
}
.name {
	display: flex;
	align-items: center;
	.num {
		color: white;
		border-radius: 50%;
		width: 50px;
		height: 50px;
		min-width: 50px;
		border: 1px solid white;
		@include font-size(17px);
		letter-spacing: 0.01em;
		text-transform: uppercase;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 10px;
		@include antialiased;
		@include media-breakpoint-up(md) {
			width: 60px;
			height: 60px;
			@include font-size(20px);
			margin-right: 17px;
		}
	}
	h3 {
		margin: 0;
		letter-spacing: 0.01em;
		text-transform: uppercase;
		color: white;
		@include font-size(26px);
		@include antialiased;
		@include media-breakpoint-up(md) {
		}
	}
}
.buttonAcc {
	@include media-breakpoint-down(md) {
		margin-top: 1em;
	}
	button,
	a {
		border: none;
		background: transparent;
		box-shadow: none;
		outline: none;
		background: white;
		color: black;
		letter-spacing: 0.01em;
		text-transform: uppercase;
		@include font-size(15px);
		padding: 17px 52px;

		border-radius: 40px;
		transition: all 0.2s;
		white-space: nowrap;
		@include media-breakpoint-up(md) {
			@include font-size(18px);
		}
		&:after {
			content: '';
			display: inline-block;
			border: solid;
			border-color: black;
			border-width: 0 2px 2px 0;
			display: inline-block;
			padding: 4px;
			transform: rotate(45deg);
			vertical-align: super;
			position: relative;
			top: 3px;
			margin-left: 20px;
			transition: all 0.2s;
		}
	}
}
