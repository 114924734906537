.btn-primary {
	color: $primary;
	background-color: $secondary;
	border-color: $secondary;
	border-radius: 50px;
	text-transform: uppercase;

	letter-spacing: 0.02em;
	padding: 18px 40px;
	font-size: flexible-font(14, 27);
	@include media-breakpoint-up(md) {
		padding: 23px 60px;
	}

	&:hover,
	&:active,
	&:focus {
		color: $primary;
	}
	&.arrow {
		&:after {
			content: '';
			display: inline-block;
			width: 30px;
			height: 18px;
			background: url(../img/btn-arrow.svg) center no-repeat;
			background-size: contain;
			margin-left: 40px;
			position: relative;
			top: 2px;

			left: 0;
			transition: left 0.2s;
			@include media-breakpoint-down(md) {
				transform: scale(0.8);
				top: 4px;
				margin-left: 20px;
			}
		}
		&:hover {
			&:after {
				left: 5px;
			}
		}
	}
}
.scroll {
	@include ir;

	width: 20vw;
	height: 20vw;

	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 100px;
	min-height: 100px;
	&:after {
		content: '';
		display: inline-block;
		padding-bottom: 100%;
	}

	svg {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;

		circle {
			stroke-dasharray: 433;
			stroke-dashoffset: 433;
		}
		path {
			transform: translateY(0);
			transition: transform 0.2s;
		}
	}
	@include media-breakpoint-up(sm) {
		width: 15vw;
		height: 15vw;
	}
	@include media-breakpoint-up(md) {
		width: 10vw;
		height: 10vw;
		max-width: 200px;
		max-height: 200px;
	}
}
.singleArrow {
	@include ir;
	background-color: white;
	border-radius: 50px;

	display: inline-flex;
	justify-content: center;
	align-items: center;
	max-height: 69px;
	min-width: 106px;

	padding: 6% 11%;

	&:after {
		content: '';
		display: inline-block;
		width: 26px;
		height: 13px;
		background: url(../img/btn-arrow.svg) center no-repeat;
		background-size: contain;
		@include media-breakpoint-up(lg) {
			width: 2vw;
			height: 1vw;
		}
		@media only screen and (min-width: 1500px) {
			width: 1.4vw;
			height: 1vw;
		}
	}
}
