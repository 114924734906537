// SASS MIXINS
// common css parts
// -----------------------------------

//vyhlazovani textu
@mixin antialiased {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
//logo
/*
* pro vymenu textu za obrazek
*/
@mixin ir {
	display: block;
	border: 0;
	text-indent: -999em;
	overflow: hidden;
	background-color: transparent;
	background-repeat: no-repeat;
	text-align: left;
	direction: ltr;
	*line-height: 0;
}
//krizek
@mixin closeIcon($size, $width, $color) {
	width: $size;
	height: $size;
	display: block;
	position: absolute;

	&:before,
	&:after {
		content: ' ';
		position: absolute;
		left: 50%;
		top: -22%;

		height: 141.42%;
		width: $width;
		background-color: $color;
	}

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
}
//roztazeni obrazku
@mixin cover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
//pomerova vyska
@mixin ratio($height) {
	&:after {
		content: '';
		display: block;
		padding-bottom: $height;
	}
}
//vystredeni elementu
@mixin centerElement {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
//otoceni elementu na vysku
@mixin rotateElement {
	writing-mode: vertical-rl;
	transform: rotate(180deg);
}
//default kruh s vyplni
@mixin circle($width, $color) {
	width: $width;
	height: $width;
	background: $color;
	border-radius: $width/2;
}
//default ctyruhelnik s vyplni
@mixin rectangle($width, $height, $color) {
	width: $width;
	height: $height;
	background: $color;
}

// default underline textu
@mixin underline($height, $margin, $color) {
	position: relative;
	display: inline-block;

	&:before {
		position: absolute;
		content: '';
		display: block;
		height: $height;
		background-color: $color;
		left: 0;
		width: 100%;
		bottom: -$margin;
	}
}

// podtrzeni viceradkoveho textu
@mixin multilineUnder($color, $width, $height) {
	display: inline;
	background-image: linear-gradient(to right, $color, $color);
	background-position: bottom left;
	background-repeat: no-repeat;
	background-size: $width $height;
}
//default styl pro ul
@mixin listDefault {
	padding: 0;
	list-style: none;
}

//text s vyplni a outline
@mixin textOutline($width, $outline, $fill) {
	color: $outline;
	-webkit-text-fill-color: $fill;
	-webkit-text-stroke-width: $width;
	-webkit-text-stroke-color: $outline;
}
//badge
@mixin badge($url, $width, $height) {
	&:before {
		width: $width;
		height: $height;
		background: url($url) 0 0 no-repeat;
		background-size: 100%;
		position: absolute;
		content: ' ';
		z-index: 1;
	}
}
// slide easing
@mixin slideEase {
	transition-timing-function: cubic-bezier(0.88, 0.01, 0.125, 0.995);
}

@function flexible-font($fzMin, $fzMax, $vwMin: 320, $vwMax: 2280) {
	$vtop: 100 * ($fzMax - $fzMin);
	$vbot: $vwMax - $vwMin;
	$v: $vtop / $vbot;

	$rtop: ($vwMin * $fzMax) - ($vwMax * $fzMin);
	$rbot: $vwMin - $vwMax;
	$r: $rtop / $rbot;

	$r-rem: $r/16;

	$fz-min: $fzMin/16;
	$fz-max: $fzMax/16;

	@return clamp(#{$fz-min}rem, #{$v}vw + #{$r-rem}rem, #{$fz-max}rem);
}
