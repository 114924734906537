.page-homepage {
	section.top {
		margin-bottom: 4em;
		@include margin-top(120px);
		/* GSAP Flicker fix */
		visibility: hidden;
		opacity: 0;
		@include media-breakpoint-up(md) {
			margin-bottom: 9em;
		}
		.headingWrap {
			position: relative;
		}
		h2 {
			text-transform: uppercase;
			line-height: 102%;
			position: relative;
			margin-top: 0;
			margin-bottom: 0;
			font-size: flexible-font(40, 220);
			text-align: center;
			@include antialiased;
			margin-bottom: 0.2em;

			@include media-breakpoint-up(md) {
				margin-left: 0.1em;
				left: -10px;
				text-align: left;
			}

			.line .lineInner {
				height: 1.1em;
				padding-top: 0.3em;

				@include media-breakpoint-up(md) {
					height: 1em;
					padding-top: 0.2em;
				}
			}
		}
		h3.subtextPolygon {
			text-align: center;
			display: block;
			@include media-breakpoint-up(md) {
				display: inline-block;
				margin: 0.6em 0 2em 4.5em;
				text-align: left;
			}
		}
		.image {
			position: relative;

			.img {
				width: 100%;
				@include ratio(85%);
				border-radius: 20px;
				@include media-breakpoint-up(md) {
					@include ratio(55%);
				}
				img {
					border-radius: 20px;
				}
			}
			a.scroll {
				position: absolute;
				right: 6px;
				top: 40px;
				transform: translateY(-100%);
				@include media-breakpoint-up(sm) {
					top: 16px;
					right: 26px;
				}
				@include media-breakpoint-up(md) {
					top: 26px;
				}
			}
		}
	}

	section.claim {
		margin: 4em 0;
		@include media-breakpoint-up(md) {
			margin: 8em 0;
		}

		svg {
			transform-origin: center;
			transform: scale(0.7);
			@include media-breakpoint-up(sm) {
				transform: scale(0.8);
				margin-left: 15px;
			}
			@include media-breakpoint-up(md) {
				position: relative;
				top: -5px;
				transform: scale(1);
			}
		}
		p {
			line-height: 119%;
			letter-spacing: -0.01em;
			text-transform: uppercase;
			@include antialiased;
			font-size: #{'max(3vw, 2em,42px )'};
			font-size: flexible-font(20, 70);
		}
	}

	section.about {
		margin: 7em 0px 4em 0px;
		@include media-breakpoint-up(md) {
			margin: 16em 30px 8em 30px;
		}

		.subtextPolygon {
			margin: 0;
			@include media-breakpoint-down(xl) {
				margin-top: 1em;
			}
		}
		.wrap {
			background-color: #212323;
			border-radius: 30px;
			display: flex;
			padding: 50px 35px;
			flex-direction: column;
			@include media-breakpoint-up(xl) {
				flex-direction: row;
			}
			.leftPart {
				width: 100%;
				@include media-breakpoint-up(xl) {
					width: 60%;
				}
				.img {
					border-radius: 5px;
					width: 100%;
					@include ratio(100%);
					img {
						border-radius: 5px;
					}
				}
				svg {
					position: absolute;
					bottom: calc(60% - 10px);
					left: 20px;
					@include media-breakpoint-up(md) {
						bottom: 40px;
						left: 40px;
					}
				}
			}
			.rightPart {
				@include media-breakpoint-up(xl) {
					width: 40%;
				}

				display: flex;
				flex-direction: column;
				justify-content: space-between;
				@include media-breakpoint-up(xl) {
					@include padding-left(80px);
				}
				h3 {
					line-height: 137%;
				}
				ul {
					li {
						display: flex;
						align-items: center;
						border-bottom: 1px solid rgba(white, 0.2);
						padding: 70px 0px 10px 0px;
						&:last-of-type {
							border-bottom: none;
						}
						strong {
							font-family: 'PP Neue', sans-serif;
							font-weight: 500;
							font-size: flexible-font(50, 114);
							line-height: 1;
							text-transform: uppercase;
							margin-right: 20px;
						}
					}
				}
			}
		}
	}

	section.list {
		article.product {
			&:hover {
				.text h3 span {
					background-size: 100% 2px;
				}
			}
			.text {
				text-align: left;

				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				h3 {
					text-decoration: none;
					margin-right: 15px;
					@include antialiased;
					span {
						@include multilineUnder(white, 0%, 2px);
						transition: background-size 0.2s;
					}
				}
				small {
					text-transform: lowercase;
					letter-spacing: 0.02em;
					color: rgba(black, 0.5);
					margin: 0;
					line-height: 1;
					font-size: flexible-font(14, 24);
					position: relative;
					top: -3px;
				}
			}
		}
	}
}
