/* classes for working with split type */
.line {
	display: block;
	overflow: hidden;
}
.line .innerLine {
	position: relative;
}

.overflowNormal,
.overflowNormal .line {
	overflow: visible;
}
