.page-404 {
	section.top {
		min-height: calc(100vh - 90px);

		margin: 0 30px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		@include media-breakpoint-up(md) {
			justify-content: space-evenly;
			min-height: calc(100vh - 123px);
		}
	}

	h2 {
		text-transform: uppercase;
		font-size: flexible-font(50, 150);
		margin: 0;
		margin-bottom: 1em;
	}
	footer {
		display: none;
	}
}
